<template>
  <div class="frequent-questions">
        <h2 class="frequent-questions.title">Frequently asked questions</h2>
        <div class="frequent-questions.container">
            <div v-for="accordion in accordions" :key="accordion.title">

                <BaseAccordion>
                    <template v-slot:number>
                        {{ accordion.id }}
                    </template>
                    <template v-slot:title>
                        {{ accordion.title }}
                    </template>

                    <template v-slot:content>
                        <div v-html="accordion.text"></div>
                    </template>
                </BaseAccordion>

            </div>
        </div>
  </div>
</template>

<script>
    import BaseAccordion from '@/components/BaseAccordion';

    export default {
        components: {
            BaseAccordion
        },

        data() {
            return {
                accordions: [
                    {
                        id: 1,
                        title: 'When can collection be arranged for?',
                        text: "Collection can be arranged for any day within the next three days from order date. This excludes weekends and bank holidays. Specific collection times are currently unable, however you can select a safe place if you won't be at home."
                    },
                    {
                        id: 2,
                        title: 'Do I need to complete a customs form?',
                        text: 'If you are sending an item outside the UK, you will need to complete a customs form to declare the details of the items you are sending. Customs information is not required if you are only sending documents.'
                    },
                    {
                        id: 3,
                        title: 'How do I complete a customs declaration?',
                        text: 'To complete the customs declaration, we have provided an online form for you to fill out before you place your order. Please complete all fields that are requested.'
                    },
                    {
                        id: 4,
                        title: 'Are there any items that I am restricted from sending?',
                        text: 'Yes. Due to regulations across the world, some items are prohibited. Our full list is provided before you place your order. You cannot send any restricted or prohibited items through Online Postage and should seek advice from a counter or Customer Services should you be unsure.'
                    },
                    {
                        id: 5,
                        title: 'Are there any countries I cannot send to?',
                        text: 'From time to time, various factors may restrict access to some countries for postal services. We endeavour to update our systems to reflect the current worldwide availability of services.'
                    },
                    {
                        id: 6,
                        title: "I'm sending items on behalf of a business, how do I use IOSS?",
                        text: "Click & Dispatch is our online shipping platform for businesses where IOSS can be used. <a href='https://www.iompost.com/business-solutions/outbound-mailing-solutions/click-and-dispatch/'>Apply for an account.</a>"
                    }
                ]
            }
        },
    };
</script>
