<template>
    <div class="login-modal">
        <div class="login-modal.content">
            <span class="login-modal.content.close" @click="handleClick"></span>
            <div class="login-modal.content.tabs">
                <a href="#" class="login-modal.content.tabs.tab" :class="{ active: isSignup, '--active': isSignup }"
                    @click.prevent="setTab('signup')">Sign up</a>
                <a href="#" class="login-modal.content.tabs.tab" :class="{ active: !isSignup, '--active': !isSignup }"
                    @click.prevent="setTab('signin')">Sign in</a>
            </div>
            <div class="login-modal.content.form">
                <h2 class="login-modal.content.form.title">{{ buttonText }}</h2>
                <form @submit.prevent="handleSubmit">
                    <input class="form-text-field" v-model="formData.firstname" type="text" placeholder="First name" required v-if="isSignup"/>
                    <input class="form-text-field" v-model="formData.lastname" type="text" placeholder="Last name" required v-if="isSignup"/>
                    <input class="form-text-field" v-model="formData.email" type="email" placeholder="Email" required />
                    <input class="form-text-field" v-model="formData.password" type="password" placeholder="Password" required />
                    <input class="form-text-field" v-model="confirmedPassword" type="password" placeholder="Confirm password" required v-if="isSignup" @input="validatePassword"/>
                    <!--<TypeaheadAddress v-if="isSignup"/>-->
                    <MainButton :btntxt="buttonText" />
                </form>
                <p v-if="errorMessage" class="error --sign-up --centered">{{ errorMessage }}</p>
                <p v-if="passwordValid === false" class="error --sign-up --centered">Passwords do not match</p>
            </div>
        </div>
    </div>
</template>


<script>
import MainButton from '@/components/MainButton';
import { useMainStore } from "@/stores/MainStore.ts";
import axios from 'axios';
//import TypeaheadAddress from './TypeaheadAddress.vue';

export default {
    name: 'LoginModal',

    components: {
        MainButton,
        //TypeaheadAddress
    },

    data() {
        return {
            isSignup: true,
            formData: {
                firstname: '',
                lastname: '',
                email: '',
                password: ''
            },
            confirmedPassword: '',
            errorMessage: '',
            confirm: false,
            passwordValid: null,
        };
    },

    computed: {
        mainstore() {
            return useMainStore();
        },
        buttonText() {
            return this.isSignup ? 'Sign up' : 'Sign in';
        },
        endpoint() {
            if (this.isSignup) {
                return process.env.VUE_APP_API_REGISTER;
            } else {
                return process.env.VUE_APP_API_SIGNIN;
            }
        },
        errorText() {
            return this.isSignup ? 'sign up' : 'sign in';
        },

        payload() {
            if (this.isSignup) {
                return {
                    "username": this.formData.email,
                    "firstName": this.formData.firstname,
                    "lastName": this.formData.lastname,
                    "email": this.formData.email,
                    "password": this.formData.password
                }
            } else {
                return {
                    "identifier": this.formData.email,
                    "password": this.formData.password
                }
            }
        }
    },

    methods: {
        setTab(tab) {
            this.isSignup = (tab === 'signup');
            this.formData.email = '';
            this.formData.password = '';
            this.errorMessage = '';
        },

        async handleSubmit() {
            let payload = this.payload;
            try {
                const response = await axios.post(this.endpoint, payload);
                this.mainstore.user = response.data; // Store user data in mainstore
                this.mainstore.loginModal = false; // Close the login modal
                this.mainstore.modalOpen = false; // Close any other modal
                this.confirm = true; // Set confirmation flag
            } catch (error) {
                // Handle error and set error message
                // Check if error response exists and get the message accordingly
                this.errorMessage = `Failed to ${this.errorText}, please try again.`;
            }
        },

        handleClick() {
            this.mainstore.loginModal = false;
            this.mainstore.modalOpen = false;
        },

        validatePassword() {
            if(this.formData.password === this.confirmedPassword) {
                this.passwordValid = true
            } else {
                this.passwordValid = false
            }
        }
    }
};
</script>