<template>
    <div class="account-details">
        <span class="account-details.close" @click="handleClose"></span>
        <h2 class="account-details.title">My account</h2>
        <p class="account-details.data" v-for="item in mainstore.user.user" :key="item">{{ item }}</p>
        <span class="account-details.signout" @click="signOut">Sign out</span>
        <span class="account-details.delete" @click="deleteAccount">Delete account</span>
    </div>
</template>

<script>

import { useMainStore } from '@/stores/MainStore.ts';

export default {
    name: 'AccountDetails',

    setup() {
        const mainstore = useMainStore();

        return {
            mainstore
        }
    },

    methods: {
        handleClose() {
            this.$emit('accountClosed');
            this.mainstore.modalOpen = false;
        },

        signOut() {
            this.handleClose();
            this.mainstore.user = null;
        },
    }
}

</script>