
import { createApp } from 'vue'
import './scss/styles.scss';
import App from './App.vue';
import { createPinia } from 'pinia';
import axios from 'axios';
import VueAxios from 'vue-axios';
import SimpleTypeahead from 'vue3-simple-typeahead';
import { createRouter, createWebHistory } from 'vue-router';
import { createGtm } from '@gtm-support/vue-gtm';

import GetStarted from './components/GetStarted.vue';
import MainBasket from './components/MainBasket.vue';
import OrderForm from './components/OrderForm.vue';
import ParcelSize from './components/ParcelSize.vue';
import AvailableServices from './components/AvailableServices.vue';
import RecipientDetails from './components/RecipientDetails.vue';
import SendersDetails from './components/SendersDetails.vue';
import CustomsForm from './components/CustomsForm.vue';
import OrderSummary from './components/OrderSummary.vue';
import SafeToFly from './components/SafeToFly.vue';
import LabelsContent from './components/LabelsContent.vue';
import PostboxContent from './components/PostboxContent.vue';
import CollectionContent from './components/CollectionContent.vue';
import HowtoContent from './components/HowtoContent.vue';
import TermsConditions from './components/TermsConditions.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import CookiePolicy from './components/CookiePolicy.vue';
import FaqContent from './components/FaqContent.vue';
import WorldpayCheckout from './components/WorldpayCheckout.vue';
import NotFound from './components/NotFound.vue';
import OrderConfirmation from './components/OrderConfirmation.vue';
import ConfirmationError from './components/ConfirmationError.vue';
import PaymentError from './components/PaymentError.vue';
import ProvideItems from './components/ProvideItems.vue';

import { useMainStore } from '@/stores/MainStore.ts';
import { useBasketStore } from './stores/BasketStore.ts'
import { useCollectionStore } from './stores/CollectionStore.ts';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { 
            path: '/', 
            name: 'home',
            component: GetStarted,
            meta: { title: 'Home' }
        },
        {
            path: '/labels',
            name: 'labels',
            component: LabelsContent,
            meta: { title: 'Labels' }
        },
        {
            path: '/parcel-postboxes',
            name: 'parcelpostboxes',
            component: PostboxContent,
            meta: { title: 'Parcel Postboxes' }
        },
        {
            path: '/collection',
            name: 'collection',
            component: CollectionContent,
            meta: { title: 'Collection' }
        },
        {
            path: '/how-to',
            name: 'howto',
            component: HowtoContent,
            meta: { title: 'How to' }
        },
        {
            path: '/faq',
            name: 'faq',
            component: FaqContent,
            meta: { title: 'Frequently Asked Questions' }
        },
        {
            path: '/termsandconditions',
            name: 'termsandconditions',
            component: TermsConditions,
            meta: { title: 'Terms and Conditions' }
        },
        {
            path: '/privacypolicy',
            name: 'privacypolicy',
            component: PrivacyPolicy,
            meta: { title: 'Privacy Policy' }
        },
        {
            path: '/cookiepolicy',
            name: 'cookiepolicy',
            component: CookiePolicy,
            meta: { title: 'Cookie Policy' }
        },
        { 
            path: '/my-order',
            name: 'myorder',
            component: OrderForm,
            children: [
                {
                    path: 'provide-items',
                    name: 'provideItems',
                    component: ProvideItems,
                    meta: { title: 'Provide Items' }
                },
                {
                    path: 'parcel-size',
                    name:   'parcelSize',
                    component: ParcelSize,
                    meta: { title: 'Parcel Size' }
                },
                {
                    path: 'services',
                    name: 'services',
                    component: AvailableServices,
                    meta: { title: 'Available Services' }
                },
                {
                    path: 'recipient-details',
                    name: 'recipient',
                    component: RecipientDetails,
                    meta: { title: 'Recipient Details' }
                },
                {
                    path: 'senders-details',
                    name: 'sender',
                    component: SendersDetails,
                    meta: { title: 'Senders Details' }
                },
                {
                    path: 'customs',
                    name: 'customs',
                    component: CustomsForm,
                    meta: { title: 'Customs' }
                },
                {
                    path: 'order-summary',
                    name: 'orderSummary',
                    component: OrderSummary,
                    meta: { title: 'Order Summary' }
                },
                {
                    path: 'safe-to-fly',
                    name: 'safeToFly',
                    component: SafeToFly,
                    meta: { title: 'Safe to Post' }
                }
            ]
        },
        {
            path: '/basket',
            name: 'basket',
            component: MainBasket,
            meta: { title: 'Basket' }
        },
        {
            path: '/checkout',
            name: 'checkout',
            component: WorldpayCheckout,
            meta: { title: 'Checkout' }
        },
        {
            path: '/confirmation/:consignmentRef?',
            name: 'confirmation',
            component: OrderConfirmation,
            meta: { title: 'Confirmation' }
        },
        {
            path: '/confirmation-error',
            name: 'confirmationError',
            component: ConfirmationError,
            meta: { title: 'Error' }
        },
        {
            path: '/payment-error',
            name: 'paymentError',
            component: PaymentError,
            meta: { title: 'Error' }
        },
        {
            path: '/:catchAll(.*)',
            name: 'Error404',
            component: NotFound,
            meta: { title: 'Not Found' }
        }
    ],

    // Make page scroll to top on route change
    scrollBehavior: function (to, _from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { el: to.hash, behavior: "smooth" };
        } else {
            window.scrollTo(0, 0);
        }
    },
});

let lastRouteName = null;

router.beforeEach(async (to, from, next) => {
    const mainStore = useMainStore();
    const basketStore = useBasketStore();
    const collectionStore = useCollectionStore();

    if (basketStore.lastUpdated !== null && basketStore.isOlderThanMidnight()) {
        basketStore.resetBasket();
      }
    
      if (collectionStore.lastUpdated !== null && collectionStore.isOlderThanMidnight()) {
        collectionStore.resetCollection();
      }
    
    if (from.name === 'basket') {
        await mainStore.reset();
    }

    // Detect navigation from /checkout to /basket
    if (from.name === 'checkout' && to.name === 'basket') {
        mainStore.setNavigatedFromCheckout(true);
    } else {
        mainStore.setNavigatedFromCheckout(false);
    }

    lastRouteName = from.name;
    next();
});

router.afterEach((to) => {
    const defaultTitle = 'Online Postage';
    document.title = to.meta.title || defaultTitle;
});


import { useGtm } from '@gtm-support/vue-gtm';

router.afterEach((to) => {
    const gtm = useGtm();

    if (to.name === 'confirmation') {
        gtm.trackEvent({
            event: 'conversion',
            category: 'Transaction',
            action: 'Complete',
            label: 'Order Confirmation',
            value: 1,
        });
    } else {
        gtm.trackView({
            event: 'pageview',
            page_title: to.name || document.title,
            page_path: to.fullPath,
        });
    }
});

window.addEventListener('popstate', () => {
    if (lastRouteName === 'basket') {
        router.replace({ name: 'home' });
        lastRouteName = null;
    }
});

window.resetLocalStorage = () => {
    localStorage.removeItem("basketLastUpdated")
    localStorage.removeItem("consignment")
    localStorage.removeItem("Validations")
    localStorage.removeItem("sendersDetails")
    localStorage.removeItem("basket")
    localStorage.removeItem("CollectionData")
    localStorage.removeItem("Selected")
    localStorage.removeItem("collectionLastUpdated")
}

// Initialise 
const app = createApp(App);
const pinia = createPinia();

//pinia.use(piniaPluginPersistedstate);

export default pinia;

// app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY, loaderOptions: { autoHideBadge: false } })
//pinia.use(async ({ store }) => {
//    await app.config.globalProperties.$recaptchaLoaded()
//    store.$recaptcha = app.config.globalProperties.$recaptcha;
//});

app.use(VueAxios, axios);
app.use(SimpleTypeahead);
app.use(router);

app.use(
    createGtm({
      id: 'GTM-W4ML3Z96',
      defer: true,
      vueRouter: router,
    }),
);
app.use(pinia); // needs to be last for dev tools

app.mount('#app');
