<template>
    <!--<PageTitle title="Select the right service for you" background="--service"/>-->
    <fieldset class="available-services">
        <!--<InsuranceOptions v-if="hasInsurance === true" :unique-insurance-values="uniqueInsuranceValues" @changed="this.animateItems(), this.mainstore.validations.serviceValid = false;"></InsuranceOptions>-->
        <!--<h2 class="available-services.text">Please choose one of the following services</h2>-->
        <div v-if="!loading" class="available-services.items">
            <h2 class="available-services.items.title">Please select a postal service</h2>
            <div class="animation" v-for="item in serviceData" :key="item.id">
                <label class="service-item" :class="{'--selected': (this.mainstore.selected.service === item.id)}">
                    <input type="radio" name="service" :key="selectedInstance" v-model="this.mainstore.selected.service" :value="item.id" @change="addToStore(), validate(), selectInstance(item.id), resetInsurance()">
                    <h2 class="service-item.name">{{ formatDisplayValue(item.expandedName) }}</h2>
                    
                    <span class="service-item.saturday-delivery" v-show="item.saturdayDelivery === true">Saturday delivery included</span>
                    <span class="service-item.time" v-if="item.deliveryGuaranteeTime !== null">Guaranteed {{ item.deliveryGuaranteeTime }}</span>
                    <!--<span class="service-item.time" v-show="item.deliveryGuaranteeTime !== null">Guaranteed next day by {{ item.deliveryGuaranteeTime }}</span>-->
                    <span class="service-item.insurance" v-if="item.maxInsuredValue !== 0 && item.maxInsuredValue !== 20">Insured up to £{{ item.maxInsuredValue }}</span>
                    <!--<span class="service-item.sending-options.item" v-if="item.parcelBox == true">Drop off at a Parcel Postbox</span>-->
                    <!-- <span class="service-item.sending-options.item" v-if="item.postOfficeCounter == true">Drop off at a Post Office Counter</span> -->
                    <!--<span class="service-item.sending-options.item" v-if="item.availableForCollection == true">Collection available</span>-->
                    
                    <p class="service-item.description">
                        <span class="service-item.description.line">{{ formatDisplayValue(item.description1) }}</span>
                        <span class="service-item.description.line">{{ formatDisplayValue(item.description2) }}</span>
                        <span class="service-item.description.line">{{ formatDisplayValue(item.description3) }}</span>
                    </p>
                    <div class="service-item.insurance-selection" v-if="item.insuranceValues.length !== 0 && this.mainstore.selected.service === item.id">
                        <label :for="'additionalInsurance_' + item.id" class="form-label">Would you like to increase the insurance?</label>
                        <select 
                            :name="'additionalInsurance_' + item.id"
                            :id="'additionalInsurance_' + item.id"
                            class="form-select-field"
                            v-model="item.selectedInsurance"
                            @change="updateInsurance(item.selectedInsurance, item.id)"
                        >
                            <option :value="null" selected disabled>Please choose...</option>
                            <option
                                v-for="(option, index) in item.insuranceValues"
                                :key="index"
                                :value="{ price: option.priceInPence, value: option.valueInPence }"
                            >
                                £{{option.valueInPence/100}} cover for an extra £{{ (option.priceInPence/100).toFixed(2) }}
                            </option>
                        </select>
                    </div>
                    <span 
                        class="service-item.price" 
                        v-if="item.selectedInsurance && item.selectedInsurance.price !== undefined && item.selectedInsurance.value !== null"
                    >   
                        £{{ ((item.priceInPence || 0) + (item.selectedInsurance.price || 0)).toFixed(2) / 100 }}
                        <span v-if="item.taxCode" class="service-item.price.tax">incl. VAT</span>
                    </span>

                    <span class="service-item.price" v-else>£{{ ((item.priceInPence || 0) / 100).toFixed(2) }}
                        <span v-if="item.taxCode" class="service-item.price.tax">incl. VAT</span>
                    </span>

                    <span class="service-item.checkmark"></span>
                    <!--
                        <img v-if="item.controlCode == 'IS'" :src="require('../assets/rm-logo.png')" class="service-item.carrier" title="Carrier: Royal Mail"/>
                        <img v-if="item.controlCode == 'PF'" :src="require('../assets/pf-logo.png')" class="service-item.carrier" title="Carrier: Parcelforce"/>
                        <img v-if="item.controlCode == 'IM'" :src="require('../assets/IOMPO-Crest.png')" class="service-item.carrier" title="Carrier: Isle of Man Post Office"/>
                    -->
                </label>
            </div>
            <div class="page-error" v-if="serviceData.length == 0">
                <p class="page-error.text">There are no services available for the options you have selected</p>
            </div>
        </div>
        <div class="page-error" v-else-if="showError === true">
            <span class="page-error.intro">Sorry, the system has encountered an error</span>
            <span class="page-error.advice">Please wait a little while and try again</span>
            <div class="page-error.button">
                <router-link to="/" class="main-button"><span class="main-button.text">Back to homepage</span></router-link>
            </div>
        </div>
        <div v-else-if="loading == true" class="loader-background">
            <LoaderIcon></LoaderIcon>
        </div>
    </fieldset>
</template>

<script>
    //import InsuranceOptions from '@/components/InsuranceOptions';
    import LoaderIcon from '@/components/form-components/LoaderIcon.vue';
    import axios from "axios";
    import { useFormStore } from '@/stores/FormStore.ts';
    import { useMainStore } from '@/stores/MainStore.ts';
    import { useBasketStore } from '@/stores/BasketStore.ts';
    import anime from 'animejs';
    


    export default {

        setup() {
            const store = useFormStore()
            const mainstore = useMainStore()
            const basketstore = useBasketStore()
            return { 
                store,
                mainstore,
                basketstore
            }
        },

        name: 'AvailableServices',

        components: {
            //InsuranceOptions,
            LoaderIcon
        },

        data() {
            return {
                isSelected: false,
                readMore: false,
                serviceData: null,
                weight: 300,
                showError: false,
                uniqueInsuranceValues: [],
                //hasInsurance: false,
                selectedInstance: null,
                loading: true
            }
        },

        mounted() {
            this.fetchServices();

            if (this.mainstore.selected.service) {
                this.selectedInstance = this.mainstore.selected.service;
            }
        },

        methods: {
            getServices() {
                axios({
                    method: 'get',
                    url: process.env.VUE_APP_API_POSTAGE_CALC,
                    params: {
                        destination: this.mainstore.selected.destination.webRiposteCode,
                        weight: this.mainstore.selected.weight,
                        itemformat: this.mainstore.selected.parcelSize.itemFormat,
                        consignmentType: this.basketstore.consignmentData.orderType
                    },
                    headers: {
                        'Authorization': process.env.VUE_APP_AUTHORIZATION,
                    }
                })
                .then(response => {
                    this.serviceData = response.data.serviceList
                        .sort((a, b) => b.priority - a.priority);
                    this.loading = false;
                    this.$nextTick(() => this.animateItems()); 
                    //const maxInsuredValues = response.data.serviceList.map(service => service.maxInsuredValue);
                    //const uniqueMaxInsuredValues = maxInsuredValues.filter((value, index, self) => {
                    //    return self.indexOf(value) === index;
                    //});
                    //this.uniqueInsuranceValues = uniqueMaxInsuredValues;

                    // Check if any of the insurance values are greater than 0
                    // const anyPositiveInsurance = this.uniqueInsuranceValues.some(value => value > 0);

                    // Update hasInsurance property
                    //this.hasInsurance = anyPositiveInsurance;
                })
                .catch(err => {
                    console.error(err);
                    this.showError = true;
                });
            },

            addToStore() {
                const selectedService = this.serviceData.find(item => item.id === this.mainstore.selected.service);
                this.mainstore.$patch((state) => {
                    state.selected.serviceDetails = selectedService;
                });
            },

            selectInstance(instanceId) {
                if (this.selectedInstance !== null) {
                    const previousInstance = this.serviceData.find(item => item.id === this.selectedInstance);
                    if (previousInstance) {
                        previousInstance.selectedInsurance = null;
                    }
                }

                this.selectedInstance = instanceId;
                this.mainstore.$patch({ selected: { service: instanceId } });

                const newInstance = this.serviceData.find(item => item.id === instanceId);
                if (newInstance) {
                    newInstance.selectedInsurance = null;
                }
            },

            updateInsurance(selectedValue, serviceId) {
                const service = this.serviceData.find(item => item.id === serviceId);
                if (service) {
                    service.selectedInsurance = selectedValue;
                    this.mainstore.$patch({ selected: { additionalInsurance: selectedValue } });
                }
            },

            resetInsurance() {
                this.mainstore.selected.additionalInsurance = 0;
            },
            
            validate() {
                this.mainstore.validations.serviceValid = true;
            },

            animateItems() {
                anime({
                    targets: '.animation',
                    translateX: [40, 0],  
                    opacity: [0, 1],      
                    delay: anime.stagger(300), 
                    duration: 1000,         
                    easing: 'easeOutExpo'  
                });
            },

            // TODO: Remove with pricing engine
            formatDisplayValue(value) {
                if (value == null) {
                    return value;
                }
                if (value.includes("small parcel")) {
                    return value.replace("small parcel", "parcel");
                } else if (value.includes("Small parcel")) {
                    return value.replace("Small parcel", "Parcel");
                } else if (value.includes("Small Parcel")) {
                    return value.replace("Small Parcel", "Parcel");
                }
                return value;
            },

            fetchServices() {
                this.loading = true;
                this.getServices();
            }
        },
    }
</script>

<style>
.loader-background {
    width: 100%;
    padding: 30px;
    text-align: center;
    background: white;
    border-radius: 10px;
}

.animation {
    opacity: 0;  
    transform: translateX(40px);
}
</style>
