<template>
    <div class="container">
        <fieldset class="senders-details">
            <h2 class="senders-details.title">Senders details</h2>
            <div v-if="basketstore.basketCount === 0">
                <label for="sendersFirstName" class="form-label">First name<b class="form-label.required">*</b></label>
                <div class="form-text-field.container" :class="{ '--valid': firstNameValid == true}">
                    <input 
                        type="text" 
                        class="form-text-field" 
                        name="sendersFirstName" 
                        v-model.trim="mainstore.selected.sendersDetails.firstName" 
                        
                        @blur="validateFirstName(), buttonValid()"
                        maxlength="15"
                    />
                </div>
                <p class="error" v-if="firstNameValid == false">You must enter a first name greater than three characters in length</p>
                <label for="sendersLastName" class="form-label">Last name<b class="form-label.required">*</b></label>
                <div class="form-text-field.container" :class="{ '--valid': lastNameValid == true}">
                    <input 
                        type="text" 
                        class="form-text-field" 
                        name="sendersLastName" 
                        v-model.trim="mainstore.selected.sendersDetails.lastName" 
                       
                        @blur="validateLastName(), buttonValid()"
                        maxlength="15"
                    />
                </div>
                <p class="error" v-if="lastNameValid == false">You must enter a last name greater than two characters in length</p>
                <p class="error" v-if="firstNameTouched && lastNameTouched && fullNameValid === false">Both the first and last name must be less than 40 characters combined</p>
                <label for="sendersContactTelephone" class="form-label">Contact telephone<b class="form-label.required">*</b></label>
                <div class="form-text-field.container" :class="{ '--valid': contactTelephoneValid == true}">
                    <input 
                        type="text" 
                        class="form-text-field" 
                        name="sendersContactTelephone" 
                        v-model.trim="mainstore.selected.sendersDetails.telephone" 
                      
                        @blur="validateContactTelephone(), buttonValid()"
                    />
                </div>
                
                <p class="error" v-if="contactTelephoneValid == false">You must enter a valid contact telephone number</p>
                <label for="sendersEmail" class="form-label">Senders email address<b class="form-label.required">*</b></label>
                <div class="form-text-field.container" :class="{ '--valid': emailValid == true}">
                    <input 
                        type="email" 
                        class="form-text-field" 
                        name="sendersEmail" 
                        v-model.trim="mainstore.selected.sendersDetails.emailAddress" 
                        
                        @blur="validateEmail(), buttonValid()"
                    />
                </div>
                <p class="error" v-if="emailValid == false">You must enter a valid email address</p>
                <label for="sendersEmailConfirmation" class="form-label">Confirm email<b class="form-label.required">*</b></label>
                <div class="form-text-field.container" :class="{ '--valid': emailConfirmationValid == true}">
                    <input 
                        type="email" 
                        class="form-text-field" 
                        name="sendersEmailConfirmation" 
                       
                        v-model.trim="confirmationEmail" 
                        @blur="confirmEmail(), buttonValid()"
                    />
                </div>
                <p class="error" v-if="emailConfirmationValid == false">The email addresses entered must match</p>
            </div>
            <div v-else class="senders-details.displayed">
                <h3 class="senders-details.displayed.intro">You have already submitted these details. If you would like to change these details, you must start over</h3>
                <div>
                    <span class="senders-details.displayed.data">{{ mainstore.sendersFullName }}</span>
                    <span class="senders-details.displayed.data">{{ mainstore.selected.sendersDetails.telephone }}</span>
                    <span class="senders-details.displayed.data">{{ mainstore.selected.sendersDetails.emailAddress }}</span>
                </div>
                <button @click="handleClick">Start over</button>
            </div>
        </fieldset>
        <div v-if="showModal" class="confirmation-modal">
            <div class="confirmation-modal.panel">
                <span class="confirmation-modal.panel.close" @click="closeConfirmationModal"></span>
                <div class="confirmation-modal.panel.content">
                    <span class="confirmation-modal.panel.content.intro">Warning!</span>
                    <span class="confirmation-modal.panel.content.title">This action will delete all data you have entered</span>
                    <span class="confirmation-modal.panel.content.text">Do you wish to continue?</span>
                    <div class="confirmation-modal.panel.content.buttons">
                        <TertiaryButton btntxt="Yes please" @click="continueToRoute()"/>
                        <MainButton btntxt="No thanks" @continue="closeConfirmationModal()"/>
                    </div>
                </div>
            </div>
        </div>

        <SendersAddress v-if="this.collectionstore.collectionData.methodOfSupply === 'COLLECTION' || this.basketstore.basketCount > 0" @edit="newAddress = false"/>
        <TypeaheadAddress v-else :valid="addressValid" @changed="updateAddress" region="IMN" />
    </div>
</template>

<script>
    import { useMainStore } from '@/stores/MainStore.ts';
    import { useBasketStore } from '@/stores/BasketStore.ts'
    import { useCollectionStore } from '@/stores/CollectionStore.ts'
    import TypeaheadAddress from '@/components/TypeaheadAddress.vue';
    import SendersAddress from '@/components/SendersAddress.vue';
    import TertiaryButton from '@/components/TertiaryButton.vue';
    import MainButton from '@/components/MainButton.vue';

    export default {
        name: 'SendersDetails',

        components: {
            TypeaheadAddress,
            SendersAddress,
            TertiaryButton,
            MainButton
        },

        setup() {
            const mainstore = useMainStore();
            const basketstore = useBasketStore();
            const collectionstore = useCollectionStore();
            return {
                mainstore,
                basketstore,
                collectionstore
            }
        },

        data() {
            return {
                firstNameValid: null,
                firstNameTouched: false,
                lastNameValid: null,
                lastNameTouched: false,
                fullNameValid: false,
                contactTelephoneValid: null,
                emailValid: null,

                confirmationEmail: '',

                emailConfirmationValid: null,
                addressLine1Valid: null,
                townCityValid: null,
                postcodeValid: null,
                addressValid: null,

                showModal: false
            }
        },

        mounted() {
            if (this.mainstore.selected.sendersDetails.address.address) {
                this.validateAddress();
            } 
            this.buttonValid();

            if(this.basketstore.basketCount > 0) {
                this.mainstore.validations.sendersDetailsValid = true;
            }
        },

        methods: {
            validateFirstName() {
                this.firstNameTouched = true;
                let data = this.mainstore.selected.sendersDetails.firstName;
                if(!data || data.length < 3) {
                    this.firstNameValid = false;
                } else {
                    this.firstNameValid = true;
                }
                this.fullNameValid = data.length + this.mainstore.selected.sendersDetails.lastName.length < 40;
            },
            validateLastName() {
                this.lastNameTouched = true;
                let data = this.mainstore.selected.sendersDetails.lastName;
                if(!data || data.length < 2) {
                    this.lastNameValid = false;
                } else {
                    this.lastNameValid = true;
                }
                this.fullNameValid = data.length + this.mainstore.selected.sendersDetails.firstName.length < 40;
            },
            validateContactTelephone() {
                let data = this.mainstore.selected.sendersDetails.telephone;
                // eslint-disable-next-line
                if(/^[\(\)\+ 0-9]{10,20}$/.test(data)) {
                    this.contactTelephoneValid = true;
                } else {
                    this.contactTelephoneValid = false;
                }
            },
            validateEmail() {
                let data = this.mainstore.selected.sendersDetails.emailAddress;
                // eslint-disable-next-line
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data)) {
                    this.emailValid = true;
                } else {
                    this.emailValid = false;
                }
            },
            confirmEmail() {
                let firstEmail = this.mainstore.selected.sendersDetails.emailAddress;
                let secondEmail = this.confirmationEmail;

                if(firstEmail === secondEmail) {
                    this.emailConfirmationValid = true;
                } else {
                    this.emailConfirmationValid = false;
                }
            },

            validateAddress() {
                const address = this.mainstore.selected.sendersDetails.address.address;
                if (address && address.POSTCODE !== null) {
                    this.addressValid = true;
                } else {
                    this.addressValid = false;
                }

                this.basketstore.sendersDetails = this.mainstore.selected.sendersDetails;
            },

            updateAddress(address) {
                this.addressValid = true
                this.buttonValid()

                this.selectedAddressFormatted = address;
                this.mainstore.selected.sendersDetails.address = address;
                this.collectionstore.collectionData.collectionAddress = address;
                this.basketstore.sendersDetails.address = address;
            },

            buttonValid() {
                const {
                    firstNameValid,
                    lastNameValid,
                    contactTelephoneValid,
                    emailValid,
                    emailConfirmationValid,
                    addressValid
                } = this;
                this.mainstore.validations.sendersDetailsValid =
                    firstNameValid &&
                    lastNameValid &&
                    contactTelephoneValid &&
                    emailValid &&
                    emailConfirmationValid &&
                    addressValid;
                if (this.mainstore.validations.sendersDetailsValid === null) {
                    this.mainstore.validations.sendersDetailsValid = false;
                }
            },

            handleClick() {
                this.showModal = true;
            },

            closeConfirmationModal() {
                this.showModal = false;
            },

            continueToRoute() {
                window.resetLocalStorage();
                this.mainstore.reset();
                this.collectionstore.resetCollection();
                this.basketstore.resetBasket();

                this.$router.push('/');
            }

        }
    }
</script>
