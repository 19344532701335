<template>
    <div class="confirmation-error">
        <div class="content">
            <div class="page-error">
                <span class="page-error.intro">Unfortunately your payment could not be processed</span>
                <ul class="page-error.advice">
                    <li>Please check your payment details or try another card</li>
                    <li>Ensure your billing address matches your card details</li>
                    <li>If using a VPN, disconnect it and attempt payment again</li>
                </ul>
                <span class="page-error.advice">If the issue persists, contact your bank or card provider</span>
                <div class="page-error.button">
                    <router-link to="/basket" class="main-button"><span class="main-button.text">Back to Basket</span></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .confirmation-error {
        margin-top: 60px;
    }

    ul {
        list-style: disc;
        list-style-position: inside;
    }
</style>
