<template>
  <SkipLinks/>
  <SiteNavigation @accountClicked="openAccountDetails"/>
  <form @submit.prevent="onSubmit" autocomplete="off">
    <router-view></router-view>
  </form>
  <PostageProcess v-show="this.$route.name == 'home'"/>
  <PostageOptions v-show="this.$route.name == 'home'"/>
  <FrequentQuestions/>
  <SiteFooter/>
  <SystemDowntime @accepted="closeModal"/>
  <CookiePrompt @accepted="closeModal" />
  <InterstitialLoader v-if="mainstore.pageLoading === true"/>
  <LoginModal v-if="mainstore.loginModal"/>
  <AccountDetails v-if="this.accountOpen" @accountClosed="closeAccount"/>
</template>

<script>
  import SkipLinks from './components/SkipLinks.vue';
  import SiteNavigation from './components/SiteNavigation.vue';
  import PostageProcess from './components/PostageProcess.vue';
  import PostageOptions from './components/PostageOptions.vue';
  import FrequentQuestions from './components/FrequentQuestions.vue';
  import SiteFooter from './components/SiteFooter.vue';
  import SystemDowntime from './components/SystemDowntime.vue';
  import CookiePrompt from './components/CookiePrompt.vue';
  import InterstitialLoader from './components/InterstitialLoader.vue';
  import { useMainStore } from '@/stores/MainStore.ts';
  import LoginModal from './components/LoginModal.vue';
  import { computed } from 'vue';
  import AccountDetails from './components/AccountDetails.vue';
  import { useRouter } from 'vue-router';

  export default {
    setup() {
      const router = useRouter();
      const mainstore = useMainStore();

      const popoverActive = computed({
        get: () => mainstore.modalOpen,
        set: (value) => {
          mainstore.modalOpen = value;
        }
      });

      router.afterEach((to) => {
        /* global fbq */
        if (to.path !== '/')
          fbq('track', 'ViewContent');
      });

      return { 
        mainstore,
        popoverActive,
      }
    },

    components: {
      SiteNavigation,
      SkipLinks,
      FrequentQuestions,
      SiteFooter,
      PostageProcess,
      PostageOptions,
      SystemDowntime,
      CookiePrompt,
      InterstitialLoader,
      LoginModal,
      AccountDetails
    },

    data() {
      return {
        accountOpen: false
      }
    },

    watch: {
      popoverActive(value) {
        if (value) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      }
    },

    methods: {
      closeModal() {
        this.popoverActive = false;
      },

      openAccountDetails() {
        this.accountOpen = true;
      },

      closeAccount() {
        this.accountOpen = false;
      }
    }
  }
</script>
