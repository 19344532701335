<template>
  <div>
    <div v-if="showDowntimeNotification" class="system-downtime">
      <div class="system-downtime.popover">
        <span class="system-downtime.popover.close" @click="closeDowntimeNotification"></span>
        
          <div class="system-downtime.popover.content" v-for="downtime in downtimes" :key="downtime.service">
            <div v-if="
              downtime.service === 'ND_DELAYED' || 
              downtime.service === 'SD_DELAYED' ||
              downtime.service === 'ND_UNAVAILABLE' ||
              downtime.service === 'ND_UNAVAILABLE'
            ">
              <span class="system-downtime.popover.content.title">
              {{ downtime.writtenService }}
              </span>
              <span class="system-downtime.popover.content.reason">{{ downtime.reason }}</span>
            </div>

            <div v-else>
              <span class="system-downtime.popover.content.title">
              Sorry, we are currently unable to provide services from {{ downtime.writtenService }} due to:
              </span>
              <span class="system-downtime.popover.content.reason">{{ downtime.reason }}</span>
              <span class="system-downtime.popover.content.info">
                {{ downtime.writtenService }} services are currently expected to be available from:
              </span>
              <span class="system-downtime.popover.content.time">{{ formatTime(downtime.utsEnd) }}</span>
              <span class="system-downtime.popover.content.date">on {{ formatDate(downtime.utsEnd) }}</span>
            </div>
            
          </div>
          <span class="system-downtime.popover.content.info">
            We apologise for any inconvenience this may cause.
          </span>
          <button class="main-button" @click="closeDowntimeNotification">
            <span class="main-button.text">I understand</span>
          </button>
        
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SystemDowntime',

  data() {
    return {
      downtimes: [],
      showDowntimeNotification: false
    };
  },

  methods: {
    closeDowntimeNotification() {
      this.showDowntimeNotification = false;
      clearInterval(this.intervalId);
    },

    checkSystemDowntime() {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_SYSTEMDOWNTIME,
        headers: {
            'Authorization': process.env.VUE_APP_AUTHORIZATION,
        }
      }).then(response => {
        const systemDowntimes = response.data.systemDowntimes;
        let currentDate = Date.now();
        

        this.downtimes = systemDowntimes.filter(downtime => {
          // Check if isoStart and isoEnd are defined before accessing them
          const startDate = downtime.utsStart;
          const endDate = downtime.utsEnd;
          downtime.formattedService = this.formatService(downtime.service);

          // Check if startDate and endDate are defined before comparing
          return startDate && endDate && startDate <= currentDate && endDate >= currentDate;
        });

        this.showDowntimeNotification = this.downtimes.length > 0;

        if (!this.showDowntimeNotification) {
          clearInterval(this.intervalId);
        }
      }).catch(error => {
        console.error('Error fetching system downtime:', error);
      });
    },

    formatTime(time) {
      const formattedTime = new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      return formattedTime;
    },

    formatDate(date) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate = new Date(date).toLocaleDateString("en-GB", options);
      return formattedDate;
    },

    formatService(service) {
      switch(service) {
        case "ALL_SERVICES":
          return "All Providers";
        case "IOM_SERVICES":
          return "Isle of Man Post Office";
        case "PARCELFORCE":
          return "Parcelforce";
        case "ROYAL_MAIL":
          return "Royal Mail";
      }
    }
  },

  mounted() {
    this.checkSystemDowntime();
    this.intervalId = setInterval(() => {
      this.checkSystemDowntime();
    }, 60 * 5000); 
  }
};
</script>