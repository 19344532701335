<template>
  <nav class="main-menu">
    <div class="particle-container"></div>
    <div class="main-menu.content">
      <ul class="main-menu.list animate">
        <!--
        <MainButton
          btntxt="Sign up / Create account"
          style="max-width: 250px; margin-bottom: 20px"
          class="--dark"
        />
        -->
        <li class="main-menu.list.item">
          <router-link
            to="/"
            class="main-menu.list.item.link"
            @click="closeMenu(), clearData()"
            >Home</router-link
          >
        </li>
        <li class="main-menu.list.item">
          <router-link
            to="/faq"
            href="#"
            class="main-menu.list.item.link"
            @click="closeMenu(), clearData()"
            >FAQ</router-link
          >
        </li>
        <!--<li class="main-menu.list.item"><router-link to="/how-to" href="#" class="main-menu.list.item.link">How to guide</router-link></li>-->
        <li class="main-menu.list.item">
          <router-link
            to="/labels"
            class="main-menu.list.item.link"
            @click="closeMenu(), clearData()"
            >Labels</router-link
          >
        </li>
        <li class="main-menu.list.item">
          <router-link
            to="/parcel-postboxes"
            class="main-menu.list.item.link"
            @click="closeMenu(), clearData()"
            >Parcel Postboxes</router-link
          >
        </li>
        <li class="main-menu.list.item">
          <router-link
            to="/collection"
            class="main-menu.list.item.link"
            @click="closeMenu(), clearData()"
            >Collection</router-link
          >
        </li>
        <!--
                    No contact info provided so page not developed
                    <li class="main-menu.list.item"><a href="#" class="main-menu.list.item.link">Contact</a></li>
                -->
      </ul>
      <ul class="main-menu.secondary-list">
        <li class="main-menu.secondary-list.item">
          <router-link
            to="/termsandconditions"
            class="main-menu.secondary-list.item.link"
            >Terms & Conditions</router-link
          >
        </li>
        <li class="main-menu.secondary-list.item">
          <router-link
            to="/privacypolicy"
            class="main-menu.secondary-list.item.link"
            >Privacy Policy</router-link
          >
        </li>
        <li class="main-menu.secondary-list.item">
          <router-link
            to="/cookiepolicy"
            class="main-menu.secondary-list.item.link"
            >Cookie Policy</router-link
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { useMainStore } from "@/stores/MainStore.ts";
import { useCollectionStore } from "@/stores/CollectionStore.ts";
import { useBasketStore } from "@/stores/BasketStore.ts";
//import MainButton from "@/components/MainButton";

export default {
  name: "MainMenu",

  components: {
    //MainButton
  },

  setup() {
    const basketstore = useBasketStore();
    const mainstore = useMainStore();
    const collectionstore = useCollectionStore();

    return {
      basketstore,
      mainstore,
      collectionstore
    };
  },

  methods: {
    closeMenu() {
      // Emit an event to parent component to close the menu
      this.$emit("close-menu");
    },

    clearData() {
      window.resetLocalStorage();
      this.mainstore.reset();

      if (this.basketstore.basketCount < 1) {
        this.collectionstore.resetCollection();
      }
    }
  },

  mounted() {
    class Particle {
      constructor(svg, coordinates, friction, container) {
        this.svg = svg;
        this.steps = window.innerHeight / 2;
        this.item = null;
        this.friction = friction;
        this.coordinates = coordinates;
        this.position = this.coordinates.y;
        this.container = container;
        this.dimensions = this.render();
        this.rotation = Math.random() > 0.5 ? "-" : "+";
        this.scale = 0.5 + Math.random();
        this.siner = 200 * Math.random();
      }

      destroy() {
        this.item.remove();
      }

      move() {
        this.position = this.position - this.friction;
        let top = this.position;
        let left =
          this.coordinates.x +
          Math.sin((this.position * Math.PI) / this.steps) * this.siner;
        this.item.style.transform =
          "translateX(" +
          left +
          "px) translateY(" +
          top +
          "px) scale(" +
          this.scale +
          ") rotate(" +
          this.rotation +
          (this.position + this.dimensions.height) +
          "deg)";

        if (this.position < -this.dimensions.height) {
          this.destroy();
          return false;
        } else {
          return true;
        }
      }

      render() {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = this.svg.trim();
        this.item = tempDiv.firstChild;

        this.item.style.transform =
          "translateX(" +
          this.coordinates.x +
          "px) translateY(" +
          this.coordinates.y +
          "px)";

        this.container.appendChild(this.item);
        return {
          width: this.item.clientWidth,
          height: this.item.clientHeight
        };
      }
    }

    const envelope =
      '<svg viewBox="0 0 512 512"><path class="envelope" d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zm0 48v40.8c-22.4 18.3-58.2 46.7-134.6 106.5-16.8 13.2-50.2 45.1-73.4 44.7-23.2 .4-56.6-31.5-73.4-44.7C106.2 199.5 70.4 171.1 48 152.8V112h416zM48 400V214.4c22.9 18.3 55.4 43.9 104.9 82.6 21.9 17.2 60.1 55.2 103.1 55 42.7 .2 80.5-37.2 103.1-54.9 49.5-38.8 82-64.4 104.9-82.7V400H48z"/></svg>';

    const box =
      '<svg viewBox="0 0 1000 1000" ><path class="box" d="M841.2,282.3L510.6,87.9c-4.8-4.3-11.9-5.4-17.8-2.4c-0.7,0.3-1.5,0.8-2.2,1.2l-215.4,128c-1.7,0.6-3.3,1.6-4.7,2.8l-111.1,66.1c-1.2,0.6-2.2,1.4-3.2,2.3c0,0,0,0,0,0c-0.7,0.7-1.4,1.4-2,2.2c0,0-0.1,0.1-0.1,0.1c-0.6,0.8-1.1,1.6-1.5,2.4c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.5-0.4,1-0.6,1.5c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.1,0.5-0.2,0.8c-0.1,0.4-0.1,0.7-0.2,1.1c0,0.2,0,0.3,0,0.5c0,0.5-0.1,0.9-0.1,1.4v406.7c0,0.5,0,1,0.1,1.5c0,0.1,0,0.2,0,0.3c0.1,0.5,0.1,0.9,0.2,1.4c0,0.1,0,0.1,0.1,0.2c0.1,0.4,0.2,0.8,0.3,1.1c0,0.1,0.1,0.3,0.1,0.4c0.1,0.4,0.3,0.8,0.5,1.2c0,0.1,0.1,0.2,0.1,0.2c0.2,0.4,0.3,0.7,0.5,1.1c0,0.1,0.1,0.2,0.1,0.3c0.2,0.4,0.5,0.8,0.7,1.2c0,0,0,0,0,0.1c0.3,0.4,0.5,0.8,0.8,1.1c0,0,0.1,0.1,0.1,0.1c0.6,0.8,1.3,1.5,2.1,2.1c0,0,0.1,0,0.1,0.1c0.6,0.5,1.3,1,2,1.4l332.7,195.6c0.1,0,0.2,0.1,0.2,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.3,0.1,0.5,0.3,0.8,0.4c0.2,0.1,0.4,0.2,0.7,0.3c0.3,0.1,0.6,0.2,0.9,0.3c0.2,0.1,0.4,0.1,0.5,0.2c0.3,0.1,0.6,0.2,1,0.2c0.1,0,0.3,0.1,0.4,0.1c0.4,0.1,0.8,0.1,1.3,0.2c0.1,0,0.3,0,0.4,0c0.5,0,0.9,0.1,1.4,0.1h0c0,0,0,0,0,0c0.4,0,0.8,0,1.2-0.1c0.3,0,0.5,0,0.8-0.1c0.1,0,0.1,0,0.2,0c2.5-0.3,4.8-1.3,6.8-2.6l332.4-193.8c4.9-2.9,7.9-8.1,7.9-13.8V297.9C849.7,291.8,846.8,285.6,841.2,282.3z M499.6,471.6l-301-174.1l82.7-49.2l302.1,174.5L499.6,471.6z M372.4,194.2l303.2,175l-60.2,35L312.8,229.6L372.4,194.2z M631.3,432l60.1-35v93.3l-60.1,34V432z M707.4,350.7L403.9,175.5l95.7-56.9L801.4,296L707.4,350.7z M182.9,325.5l300.7,173.9V872L182.9,695.3V325.5zM817.1,696.4L515.6,872.1V499.3l83.7-48.7v101.2c0,8.8,7.2,16,16,16c2.8,0,5.5-0.7,7.9-2.1l92.1-52c5-2.8,8.1-8.2,8.1-13.9V378.4l93.6-54.4V696.4z"/></svg>';

    function randomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const data = [envelope, box];

    let isPaused = false;
    window.onblur = function () {
      isPaused = true;
    };
    window.onfocus = function () {
      isPaused = false;
    };

    let particles = [];
    const container = this.$el.querySelector(".particle-container");

    setInterval(() => {
      if (!isPaused) {
        particles.push(
          new Particle(
            data[randomInt(0, data.length - 1)],
            {
              x: Math.random() * window.innerWidth,
              y: window.innerHeight
            },
            0.5 + Math.random() * 3,
            container
          )
        );
      }
    }, 600);

    function update() {
      particles = particles.filter(function (p) {
        return p.move();
      });
      requestAnimationFrame(update);
    }
    update();
  }
};
</script>

<style>
.particle-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.envelope {
  fill: white;
  stroke: white;
  opacity: 0.2;
}

.box {
  fill: white;
  stroke: white;
  opacity: 0.2;
}

svg {
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  transform: translateZ(0px);
}
</style>
