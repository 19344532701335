<template>
    <fieldset class="safe-to-fly-confirmation">
        <h1 class="safe-to-fly-confirmation.title">Are you sending any Prohibited Items?</h1>
        <!--<p class="safe-to-fly-confirmation.text">Under legislation, you are responsible for checking whether or not an item being sent is prohibited or restricted. You must also ensure that the package is labelled correctly and includes a sender address.</p>-->
        <p class="safe-to-fly-confirmation.text">Under legislation, you are responsible for checking whether or not an item being sent is <a href="https://www.iompost.com/for-you/sending-mail/preparing-to-send-your-mail/prohibited-and-restricted-items/" target="_blank">prohibited or restricted</a>. You must also ensure that the package is labelled correctly and includes a sender address.</p>
        <p class="safe-to-fly-confirmation.text">Failure to comply with these regulations may result in items being destroyed or disposed of.</p>
        <p class="safe-to-fly-confirmation.text">Please visit <a href="https://www.iompost.com/for-you/sending-mail/preparing-to-send-your-mail/prohibited-and-restricted-items/" target="_blank">iompost.com</a> for further information</p>

        <!--
            <label class="checkbox">
                I confirm that I am not sending any item that is <a href="#">prohibited or restricted</a> in the Isle of Man, UK or overseas
                <input type="checkbox" v-model="mainstore.selected.confirmationSafe" @change="updateValidity"/>
                <span class="checkbox.checkmark"></span>
            </label>
        -->
        <label class="checkbox">
            I confirm that I am not sending any item that is <a href="https://www.iompost.com/for-you/sending-mail/preparing-to-send-your-mail/prohibited-and-restricted-items/" target="_blank">prohibited or restricted</a> in the Isle of Man, UK or overseas
            <input type="checkbox" v-model="mainstore.selected.confirmationSafe" @change="updateValidity"/>
            <span class="checkbox.checkmark"></span>
        </label>
        <label class="checkbox">
            I confirm I have read and understand the Isle of Man Post Office <router-link to="/termsandconditions">terms and conditions</router-link>
            <input type="checkbox" v-model="mainstore.selected.confirmationTerms" @change="updateValidity"/>
            <span class="checkbox.checkmark"></span>
        </label>
    </fieldset>
</template>

<script>
    import { useMainStore } from '@/stores/MainStore.ts';

    export default {
        name: 'SafeToFlyConfirmation',

        setup() {
            const mainstore = useMainStore()
            return { mainstore }
        },

        methods: {
            updateValidity() {
                if(this.mainstore.selected.confirmationSafe == true && this.mainstore.selected.confirmationTerms == true) {
                    this.mainstore.validations.safeToFlyValid = true;
                } else {
                    this.mainstore.validations.safeToFlyValid = false;
                }
            }
        }
    }

</script>